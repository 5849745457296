export enum eUserRoles {
  // voluntário - Pode visualizar escalas (Minha Agenda)
  SCHEDULER_VIEWER = 'scheduler_viewer',
  // criador de escalas - Pode criar e editar escalas (Ministérios, Eventos e Escalas)
  SCHEDULER_EDITOR = 'scheduler_editor',
  // líder - Pode gerenciar escalas, ministérios e eventos (Ministérios, Eventos e Escalas)
  SCHEDULER_MANAGER = 'scheduler_manager',
  // líder ou voluntário - Pode controlar eventos ao vivo
  LIVE_EVENTS_MANAGER = 'live_events_manager',

  // membro - Pode gerenciar a própria família
  KIDS_VIEWER = 'kids_viewer',
  // voluntário - Pode visualizar salas e fazer check-in/out
  KIDS_EDITOR = 'kids_editor',
  // líder - Pode gerenciar salas e famílias
  KIDS_MANAGER = 'kids_manager',

  // líder - Pode visualizar eventos
  CALENDAR_VIEWER = 'calendar_viewer',
  // líder - Pode criar e editar eventos
  CALENDAR_EDITOR = 'calendar_editor',
  // supervisor - Pode gerenciar salas
  CALENDAR_MANAGER = 'calendar_manager',

  // member - Pode participar de grupos
  GROUP_VIEWER = 'group_viewer',
  // leader - Pode participar, gerenciar eventos e marcar presença
  GROUP_EDITOR = 'group_editor',
  // coordinator - Pode gerenciar grupos
  GROUP_COORDINATOR = 'group_coordinator',
  // supervisor - Pode gerenciar grupos e redes
  GROUP_MANAGER = 'group_manager',

  // member - Pode visualizar os formulários
  PEOPLE_FORM_VIEWER = 'people_form_viewer',
  // revisor - Pode visualizar e revisar os formulários
  PEOPLE_FORM_EDITOR = 'people_form_editor',
  // colaborador - Pode visualizar, revisar e gerenciar os formulários
  PEOPLE_FORM_MANAGER = 'people_form_manager',

  // member - Pode visualizar os atendimentos
  PEOPLE_PASTORAL_CARE_VIEWER = 'people_pastoral_care_viewer',
  // editor - Pode criar e editar atendimentos dele mesmo
  PEOPLE_PASTORAL_CARE_EDITOR = 'people_pastoral_care_editor',
  // reviewer - Pode visualizar e revisar os atendimentos
  PEOPLE_PASTORAL_CARE_MANAGER = 'people_pastoral_care_manager',

  // member - Pode visualizar o ministério de louvor
  WORSHIP_VIEWER = 'worship_viewer',
  // leader - Pode gerenciar o ministério de louvor
  WORSHIP_EDITOR = 'worship_editor',
  // supervisor - Pode gerenciar o ministério de louvor
  WORSHIP_MANAGER = 'worship_manager'
}
