import { inject, Injectable } from '@angular/core';
import { UsersChurchApi } from '@ministrary/shared/apis/users-church.api';
import { PERMISSIONS } from '@ministrary/shared/constants/permissions.constant';
import { eAction } from '@ministrary/shared/enums/action.enum';
import { eGroupMemberType } from '@ministrary/shared/enums/group-member-type.enum';
import { eMinistryType } from '@ministrary/shared/enums/ministry-type.enum';
import { eResource } from '@ministrary/shared/enums/resources.enum';
import { eRoles } from '@ministrary/shared/enums/roles.enum';
import { eUserRoles } from '@ministrary/shared/enums/user-roles.enum';
import { iUser } from '@ministrary/shared/interfaces/user.interface';

import { AuthService } from '../auth/auth.service';
import { ChurchService } from '../church/church.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private usersChurchApi = inject(UsersChurchApi);
  private churchService = inject(ChurchService);
  public authService = inject(AuthService);

  userRoles: (eUserRoles | eRoles)[] = [];

  get isAdmin() {
    return this.userRoles?.includes(eRoles.ADMIN);
  }

  async load() {
    const { churchId } = this.churchService;
    const { id: user_id } = this.authService.currentUser;

    const churchUser = await this.usersChurchApi.loadUserRoles(user_id, churchId!);

    if (churchUser.users) {
      this.updateUserRoles(churchUser.users);
    }

    const rolesToAdd = this.userRoles.filter(role => !churchUser.user_roles?.includes(role));
    const rolesToRemove = churchUser.user_roles?.filter(role => role !== eRoles.ADMIN && !this.userRoles.includes(role));

    if (rolesToAdd.length || rolesToRemove?.length) {
      const updatedRoles = [...(churchUser.user_roles || []), ...rolesToAdd].filter(role => !rolesToRemove?.includes(role));

      await this.usersChurchApi.update({ user_roles: updatedRoles }).match({
        user_id,
        church_id: churchId!
      });

      churchUser.user_roles = updatedRoles;
    }

    this.userRoles = churchUser.user_roles || [];
    return churchUser;
  }

  hasRoles(roles: (eRoles | eUserRoles)[]) {
    if (!this.userRoles) return false;

    for (const role of roles) {
      if (this.userRoles.includes(role as eUserRoles | eRoles)) return true;
    }

    return false;
  }

  hasPermission(resource: eResource, action: eAction) {
    if (!this.userRoles) return false;

    const roles = PERMISSIONS[resource]?.[action];
    if (!roles) return false;

    for (const role of roles) {
      if (this.userRoles.includes(role)) return true;
    }

    return false;
  }

  private updateUserRoles(user: iUser) {
    const isCoordinator = !!user.group_network_coordinator?.length;
    const isSupervisor = !!user.group_network_supervisor?.length;
    const isLeader = !!user.group_members?.some(groupMember => groupMember.member_type === eGroupMemberType.LEADER);
    const isViewer = !!user.group_members?.length;

    const isVolunteer = !!user.ministry_user?.length;
    const isMinistryLeader = !!user.ministry_user?.some(ministryUser => ministryUser.is_leader);
    const isScheduler = !!user.ministry_user?.some(ministryUser => ministryUser.is_scheduler);
    const canControlLiveEvents = !!user.ministry_user?.some(ministryUser => ministryUser.can_control_live_events);

    const isKidsVolunteer = !!user.ministry_user?.some(ministryUser => ministryUser?.ministry?.is_child_ministry || ministryUser?.ministry?.type === eMinistryType.KIDS);
    const isKidsLeader = !!user.ministry_user?.some(ministryUser => ministryUser.is_leader && (ministryUser?.ministry?.is_child_ministry || ministryUser?.ministry?.type === eMinistryType.KIDS));

    const isWorshipParticipant = !!user.ministry_user?.some(ministryUser => ministryUser?.ministry?.type === eMinistryType.WORSHIP);
    const isWorshipLeader = !!user.ministry_user?.some(ministryUser => ministryUser.is_leader && ministryUser?.ministry?.type === eMinistryType.WORSHIP);

    const canManageCalendar = !!user.ministry_user?.some(ministryUser => ministryUser.can_manage_calendar);

    const isPeopleFormCollaborator = !!user.people_form_collaborators?.length;
    const isPeopleFormReviewer = !!user.people_form_reviewers?.length;

    const isPastoralCareUpdateViewer = !!user.people_pastoral_care_update_viewers?.length;
    const isPastoralCareUpdateReviewer = !!user.people_pastoral_care_reviewer?.length;

    const rolesMap: { [key: string]: boolean } = {
      [eRoles.USER]: true,

      [eUserRoles.SCHEDULER_VIEWER]: isVolunteer,
      [eUserRoles.SCHEDULER_EDITOR]: isScheduler,
      [eUserRoles.SCHEDULER_MANAGER]: isMinistryLeader,
      [eUserRoles.LIVE_EVENTS_MANAGER]: canControlLiveEvents,

      [eUserRoles.KIDS_VIEWER]: true,
      [eUserRoles.KIDS_EDITOR]: isKidsVolunteer,
      [eUserRoles.KIDS_MANAGER]: isKidsLeader,

      [eUserRoles.CALENDAR_VIEWER]: isMinistryLeader,
      [eUserRoles.CALENDAR_EDITOR]: isMinistryLeader || canManageCalendar,

      [eUserRoles.GROUP_COORDINATOR]: isCoordinator,
      [eUserRoles.GROUP_MANAGER]: isSupervisor,
      [eUserRoles.GROUP_EDITOR]: isLeader,
      [eUserRoles.GROUP_VIEWER]: isViewer,

      [eUserRoles.WORSHIP_VIEWER]: isWorshipParticipant || isWorshipLeader,
      [eUserRoles.WORSHIP_EDITOR]: isWorshipLeader,
      [eUserRoles.WORSHIP_MANAGER]: isWorshipLeader,

      [eUserRoles.PEOPLE_FORM_VIEWER]: isPeopleFormCollaborator || isPeopleFormReviewer,
      [eUserRoles.PEOPLE_FORM_EDITOR]: isPeopleFormReviewer,
      [eUserRoles.PEOPLE_FORM_MANAGER]: isPeopleFormCollaborator,

      [eUserRoles.PEOPLE_PASTORAL_CARE_VIEWER]: isPastoralCareUpdateViewer,
      [eUserRoles.PEOPLE_PASTORAL_CARE_EDITOR]: isCoordinator || isSupervisor || isLeader || isMinistryLeader,
      [eUserRoles.PEOPLE_PASTORAL_CARE_MANAGER]: isPastoralCareUpdateReviewer
    };

    this.userRoles = Object.keys(rolesMap).filter(role => rolesMap[role]) as eUserRoles[];
  }
}
